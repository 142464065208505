import { IconPhone } from "@tabler/icons-react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";

function Conditions() {
  return (
    <>
      <section className="about-page">
        <HeroPages name="Conditions" />
        <div className="container">
          <div className="conditions">
            <h1>Conditions Générales de Location BIDAWI AUTO</h1>
            <h3>Profil et âge du conducteur :</h3>
            <p>Voir pour chaque voiture sur la page d'accueil.</p>
            <h3>Préambule</h3>
            <p>
              Les conditions générales de vente suivantes régissent l'ensemble
              des transactions établies sur le catalogue web de BIDAWI AUTO.
              Toute commande passée sur ce site suppose du client son
              acceptation inconditionnelle et irrévocable de ces conditions.
            </p>
            <p>
              Le présent contrat est un contrat à distance qui a pour objet de
              définir les droits et obligations des parties dans le cadre de la
              vente des produits et services de la société BIDAWI AUTO, sur
              Internet, par l’intermédiaire de la plate-forme Maroc
              Telecommerce.
            </p>
            <h3>A. État du véhicule</h3>
            <p>
              Lors de la remise du véhicule et lors de sa restitution, l’état de
              lieu du véhicule sera établi entre le locataire et le loueur. Le
              véhicule devra être restitué dans le même état que lors de sa
              remise. Toutes les détériorations constatées sur le véhicule
              seront à la charge du locataire. Le locataire certifie être en
              possession du permis l'autorisant à conduire le présent véhicule.
            </p>
            <h3>B. État mécanique</h3>
            <p>
              Le locataire reconnait que le véhicule est en bon état de marche
              et en bonne condition et qu’il est tenu de payer le carburant
              consommé pendant la durée de la location. Les réparations
              provenant d’une panne anormale ou d’une négligence de sa part
              seront à sa charge. Les réparations provenant d’une panne normale
              seront à la charge de BIDAWI AUTO. En aucun cas, le locataire ne
              pourra prétendre à une indemnité de retard, soit à la remise du
              véhicule, à l’annulation de la location ou suite aux réparations
              nécessaires au cours de la location.
            </p>
            <h3>C. Pneus</h3>
            <p>
              Le locataire reconnait que les cinq pneus sont en bon état à la
              livraison, que leur usure est normale, et s’engage en cas de
              détérioration anormale ou de coupure de l’un d’eux, à remplacer
              immédiatement le pneu endommagé par un autre neuf de même marque
              et de même dimension.
            </p>
            <h3>D. Documents</h3>
            <p>
              Dès la fin de la location, le locataire restituera tous les
              documents nécessaires à la conduite du véhicule. À défaut de quoi,
              les pièces étant indispensables à la location, le locataire
              s’engage à payer à BIDAWI AUTO les frais administratifs y
              afférents majorés du prix de la location correspondant aux jours
              de retard occasionnés jusqu’à remise des dites pièces.
            </p>
            <h3>E. Location - Caution</h3>
            <p>
              Le montant de la location est payable à l’avance, la caution est
              obligatoire.
            </p>
            <p>
              En cas de prolongation de la location, le locataire s’engage à
              faire parvenir le montant de la location supplémentaire 48 heures
              avant l’expiration de la location en cours.
            </p>
            <p>
              Le locataire s’engage à ne pas s’opposer au paiement, par la
              caution ou par la garantie laissée dans ce cadre, des charges
              suivantes : - Les dégâts non justifiés ; - La perte des papiers du
              véhicule ; - Les frais de fourrière ou autres selon le cas, à
              condition que BIDAWI AUTO fournisse les justificatifs nécessaires
              ; - Les montants exigibles de la location du véhicule en cas de
              retard de paiement ou de prolongation.
            </p>
            <h3>F. Délits et contraventions</h3>
            <p>
              Toutes amendes, frais, dépenses et impôts sur toutes infractions à
              la législation relative à la circulation, au stationnement ou
              autres, au cours de la durée du présent contrat sont à la charge
              du locataire.
            </p>
            <h3>G. En cas d’accident</h3>
            <p>
              Le locataire s’engage par le présent contrat à prendre toutes les
              mesures utiles pour protéger les intérêts de BIDAWI AUTO et sa
              compagnie d’assurances en cas d’accident au cours de la durée du
              présent contrat.
            </p>
          </div>

          <PlanTrip />
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Réservez une voiture en nous contactant</h2>
            <span>
              <IconPhone width={40} height={40} />
              <h3>(212) 666-772871</h3>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Conditions;
