import { IconPhone } from "@tabler/icons-react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";
import BookCar from "../components/BookCar";

function Reservation() {
  return (
    <>
      <section className="about-page">
        <HeroPages name="Reservation" />
        <div className="container">
          <BookCar />
          <PlanTrip />
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Réservez une voiture en nous contactant</h2>
            <span>
              <IconPhone width={40} height={40} />
              <h3>(212) 666-772871</h3>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Reservation;
