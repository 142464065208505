import { useEffect, useState } from "react";
import corsa from "../images/cars-big/corsa2024.webp";
import logan from "../images/cars-big/logan2024.webp";
import clio from "../images/cars-big/clio55.webp";
import peugeot208 from "../images/cars-big/peugeot208.webp";
import C3 from "../images/cars-big/c3-2024.webp";
import duster from "../images/cars-big/duster.webp";
import { IconInfoCircleFilled, IconX } from "@tabler/icons-react";
import { IconMapPinFilled } from "@tabler/icons-react";
import { IoIosWarning } from "react-icons/io";

function BookCar({ pickUp: initialPickUp, handlePick: initialHandlePick }) {
  const [modal, setModal] = useState(false); //  class - active-modal

  // booking car
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [carImg, setCarImg] = useState("");
  const [acceptConditions, setAcceptConditions] = useState(false);

  // modal infos
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");

  // taking value of modal inputs
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleAge = (e) => {
    setAge(e.target.value);
  };

  // open modal when all inputs are fulfilled
  const openModal = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
    const currentDate = new Date().toISOString().split("T")[0];
    if (
      pickUp === "" ||
      dropOff === "" ||
      pickTime === "" ||
      dropTime === "" ||
      pickTime < currentDate ||
      dropTime < pickTime
    ) {
      errorMsg.style.display = "flex";
    } else {
      setModal(!modal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";
    }
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  // confirm modal booking
  const confirmBooking = (e) => {
    e.preventDefault();
    //Envoyer message vers whatsapp
    const message = `Bonjour, je souhaite réserver la voiture ${carImg} du ${pickTime} au ${dropTime} à ${pickUp} pour ${lastName} ${name}. Mon numéro de téléphone est ${phone}. Merci.`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=+212660016653&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
    //Fermer le modal
    setModal(!modal);
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "flex";
  };

  // taking value of booking inputs
  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
  };

  // based on value name show car img
  let imgUrl;
  switch (carImg) {
    case "Opel Corsa":
      imgUrl = corsa;
      break;
    case "Dacia Logan":
      imgUrl = logan;
      break;
    case "Renault Clio 5":
      imgUrl = clio;
      break;
    case "Peugeot 208":
      imgUrl = peugeot208;
      break;
    case "Citreon C3":
      imgUrl = C3;
      break;
    case "Dacia Duster":
      imgUrl = duster;
      break;
    default:
      imgUrl = "";
  }

  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };

  return (
    <>
      <section id="booking-section" className="book-section">
        {/* overlay */}
        <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>

        <div className="container">
          <div className="book-content">
            <div className="book-content__box">
              <h2>Réserver une voiture</h2>

              <p className="error-message">
                Tous les champs sont obligatoires !{" "}
                <IconX width={20} height={20} />
              </p>

              <p className="booking-done">
                Merci d'avoir choisi notre service. <br />
                <IoIosWarning />
                Si vous rencontrez des problèmes, veuillez nous contacter sur
                +212 660 016 653.{" "}
                <IconX width={20} height={20} onClick={hideMessage} />
              </p>

              <form className="box-form">
                <div className="box-form__car-type">
                  <label>
                    Lieu de prise en charge <b>*</b>
                  </label>
                  <input
                    value={pickUp}
                    onChange={handlePick}
                    type="text"
                    placeholder="Entrez le lieu de prise en charge"
                  ></input>
                </div>

                <div className="box-form__car-type">
                  <label>
                    Lieu de retour <b>*</b>
                  </label>
                  <input
                    value={dropOff}
                    onChange={handleDrop}
                    type="text"
                    placeholder="Entrez le lieu de retour"
                  ></input>
                </div>

                <div className="box-form__car-time">
                  <label htmlFor="picktime">
                    Date et heure de prise en charge <b>*</b>
                  </label>
                  <input
                    id="picktime"
                    value={pickTime}
                    onChange={handlePickTime}
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                  ></input>
                </div>

                <div className="box-form__car-time">
                  <label htmlFor="droptime">
                    Date et heure de retour <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={dropTime}
                    onChange={handleDropTime}
                    type="date"
                    min={pickTime}
                  ></input>
                </div>

                <button onClick={openModal} type="submit">
                  Rechercher
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* modal ------------------------------------ */}

      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        <div className="booking-modal__title">
          <h2>Réservation complète</h2>
          <IconX onClick={openModal} />
        </div>
        {/* message */}
        <div className="booking-modal__message">
          <h4>
            <IconInfoCircleFilled /> En complétant cette demande de réservation,
            vous recevrez :
          </h4>
          <p>
            Votre bon de location à présenter à votre arrivée au bureau de
            location et un numéro de support client gratuit.
          </p>
        </div>
        {/* car info */}
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5>Lieu et date</h5>
              <span>
                <IconMapPinFilled />
                <div>
                  <h6>Date et heure de prise en charge</h6>
                  <p>
                    {pickTime} /{" "}
                    <input type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <IconMapPinFilled />
                <div>
                  <h6>Date et heure de retour</h6>
                  <p>
                    {dropTime} /{" "}
                    <input type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <IconMapPinFilled />
                <div>
                  <h6>Lieu de prise en charge</h6>
                  <p>{pickUp}</p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <IconMapPinFilled />
                <div>
                  <h6>Lieu de retour</h6>
                  <p>{dropOff}</p>
                </div>
              </span>
            </div>
          </div>
        </div>
        {/* personal info</div> */}
        <div className="booking-modal__person-info">
          <h4>Informations personnelles</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  Nom & Prénom <b>*</b>
                </label>
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Nom complet"
                ></input>
                <p className="error-modal" style={{ color: "red" }}>
                  Ce champ est obligatoire.
                </p>
              </span>

              <span>
                <label>
                  Numéro de téléphone <b>*</b>
                </label>
                <input
                  value={phone}
                  onChange={handlePhone}
                  type="tel"
                  placeholder="Entrez votre numéro de téléphone"
                ></input>
                <p className="error-modal" style={{ color: "red" }}>
                  Ce champ est obligatoire.
                </p>
              </span>

              <span>
                <label>
                  Âge <b>*</b>
                </label>
                <input
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="18"
                ></input>
                <p className="error-modal" style={{ color: "red" }}>
                  Ce champ est obligatoire.
                </p>
              </span>
            </div>

            <span className="info-form__checkbox">
              <input
                type="checkbox"
                required
                onChange={(e) => {
                  setAcceptConditions(e.target.checked);
                }}
              ></input>
              <p>
                J'accepte <a href="/Conditions" target="_blank">les conditions générales</a> de réservation, et envoyer un
                message par Whatsapp <b>*</b>
              </p>
            </span>

            {name && phone && age >= 18 && acceptConditions ? (
              <div className="reserve-button">
                <button onClick={confirmBooking}>Réserver maintenant</button>
              </div>
            ) : (
              <div className="reserve-button">
                <button disabled>Réserver maintenant</button>
                {!acceptConditions && (
                  <p className="error-modal" style={{ color: "red" }}>
                    Veuillez accepter les conditions générales de réservation.
                  </p>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default BookCar;
