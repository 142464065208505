import "../src/dist/styles.css";
import About from "./Pages/About";
import Home from "./Pages/Home";
import Navbar from "../src/components/Navbar";
import { Route, Routes } from "react-router-dom";
import Models from "./Pages/Models";
import Contact from "./Pages/Contact";
import Reservation from "./Pages/Reservation";
import Conditions from "./Pages/Conditions";
import BannerTop from "./components/BannerTop";

function App() {
  return (
    <>
      <BannerTop />
      <div className="position-relative">
        <Navbar />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="models" element={<Models />} />
          <Route path="contact" element={<Contact />} />
          <Route path="reservation" element={<Reservation />} />
          <Route path="conditions" element={<Conditions />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
