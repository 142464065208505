import { IconPhone } from "@tabler/icons-react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";
import AboutMain from "../images/about/about-main.jpg";
import Box1 from "../images/about/icon1.png";

function About() {
  return (
    <>
      <section className="about-page">
        <HeroPages name="À propos" />
        <div className="container">
          <div className="about-main">
            <img
              className="about-main__img"
              src={AboutMain}
              alt="location-de-voiture"
            />
            <div className="about-main__text">
              <h3>À propos de l'entreprise</h3>
              <h2>Vous démarrez le moteur et votre aventure commence</h2>
              <p>
                "BIDAWI AUTO" est une entreprise de location de voitures basée
                à Fès, offrant un service de livraison dans tout le Maroc. Nous
                proposons une large gamme de véhicules, allant des voitures
                économiques aux SUV et aux voitures de luxe, pour répondre à
                tous les besoins de nos clients. Notre objectif est de fournir
                un service de qualité, avec des véhicules bien entretenus et un
                service clientèle exceptionnel, pour garantir une expérience de
                location de voiture agréable et sans souci à nos clients. Que ce
                soit pour un voyage d'affaires, des vacances en famille ou toute
                autre occasion, "BIDAWI AUTO" est là pour vous fournir la
                voiture parfaite pour votre voyage au Maroc.
              </p>
              <div className="about-main__text__icons">
                <div className="about-main__text__icons__box">
                  <img src={Box1} alt="icône-voiture" />
                  <span>
                  <p>Plus</p>
                    <h4>20</h4>
                    <p>Types de voitures</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <PlanTrip />
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Réservez une voiture en nous contactant</h2>
            <span>
              <IconPhone width={40} height={40} />
              <h3>(212) 660-016653</h3>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
