import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import {  IconPhone } from "@tabler/icons-react";
import FleetListItem from "../components/FleetListItem";
import BookCar from "../components/BookCar";

function Models() {
  return (
    <>
      <section className="models-section">
        <HeroPages name="Modèles de véhicules" />
        <div className="container">
        <FleetListItem />
        <BookCar />
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Réservez une voiture en nous contactant</h2>
              <span>
                <IconPhone width={40} height={40} />
                <h3>(212) 660-016653</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Models;
