import MainImg from "../images/chooseUs/mainservices.webp";
import Box1 from "../images/chooseUs/icon1.png";
import Box2 from "../images/chooseUs/icon2.png";
import Box3 from "../images/chooseUs/icon3.png";
import { IconChevronRight } from "@tabler/icons-react";

function ChooseUs() {
  return (
    <>
      <section className="choose-section">
        <div className="container">
          <div className="choose-container">
            <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            />
            <div className="text-container">
              <div className="text-container__left">
                <h4>Pourquoi nous choisir</h4>
                <h2>Les meilleures offres que vous trouverez jamais</h2>
                <p>
                  Découvrez les meilleures offres que vous trouverez jamais avec
                  nos offres imbattables. Nous nous engageons à vous offrir la
                  meilleure valeur pour votre argent, afin que vous puissiez
                  profiter de services et de produits de qualité supérieure sans
                  vous ruiner. Nos offres sont conçues pour vous offrir une
                  expérience de location ultime, alors ne manquez pas votre
                  chance d'économiser gros.
                </p>
                <a href="#vehicules">
                Découvrir nos voitures &nbsp;
                  <IconChevronRight />
                </a>
              </div>
              <div className="text-container__right">
                <div className="text-container__right__box">
                  <img src={Box1} alt="car-img" />
                  <div className="text-container__right__box__text">
                    <h4>Profiter de:</h4>
                    <p>
                      <ul className="services-list">
                        <li>Assurance tout risque</li>
                        <li>Kilométrage illimité</li>
                        <li>Livraison gratuite</li>
                        <li>Service client 24/7</li>
                        <li>Options de paiement sécurisé</li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  <img src={Box1} alt="car-img" />
                  <div className="text-container__right__box__text">
                    <h4>Conduite à travers le pays</h4>
                    <p>
                      Poussez votre expérience de conduite à un niveau supérieur
                      avec nos véhicules haut de gamme pour vos aventures à
                      travers le pays.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box2} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Tarification tout compris</h4>
                    <p>
                      Obtenez tout ce dont vous avez besoin dans un prix
                      pratique et transparent grâce à notre politique de
                      tarification tout compris.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box3} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Aucun frais caché</h4>
                    <p>
                      Profitez de la tranquillité d'esprit avec notre politique
                      sans frais cachés. Nous croyons en une tarification
                      transparente et honnête.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUs;
