import { IconChevronDown } from "@tabler/icons-react";
import { useState } from "react";

function Faq() {
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section">
        <div className="container">
          <div className="faq-content">
            <div className="faq-content__title">
              <h5>FAQ</h5>
              <h2>Questions fréquemment posées</h2>
              <p>
                Questions fréquemment posées sur le processus de réservation de location de voiture sur notre site web : Réponses aux préoccupations et questions courantes.
              </p>
            </div>

            <div className="all-questions">
              <div className="faq-box">
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p>1. Qu'est-ce qui est spécial dans la comparaison des offres de location de voiture ?</p>
                  <IconChevronDown />
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                  Comparer les offres de location de voiture est important car cela permet de trouver la meilleure offre qui correspond à votre budget et à vos besoins, en vous assurant d'obtenir le meilleur rapport qualité-prix. En comparant différentes options, vous pouvez trouver des offres proposant des prix plus bas, des services supplémentaires ou de meilleurs modèles de voitures. Vous pouvez trouver des offres de location de voiture en effectuant des recherches en ligne et en comparant les prix de différentes sociétés de location.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>2. Comment trouver les offres de location de voiture ?</p>
                  <IconChevronDown />{" "}
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                  Vous pouvez trouver des offres de location de voiture en effectuant des recherches en ligne et en comparant les prix de différentes sociétés de location. Des sites web tels que BIDAWI AUTO qui vous permettent de comparer les prix et de voir les options de location disponibles. Il est également recommandé de s'inscrire aux newsletters par e-mail et de suivre les sociétés de location de voitures sur les réseaux sociaux pour être informé des offres spéciales ou des promotions.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>3. Comment trouver des prix de location de voiture aussi bas ?</p>
                  <IconChevronDown />
                </div>
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
                  Réservez à l'avance : Réserver votre voiture de location à l'avance peut souvent entraîner des prix plus bas. Comparez les prix de plusieurs sociétés : Utilisez des sites web comme le notre pour comparer les prix de plusieurs sociétés de location de voitures. Recherchez des codes de réduction et des coupons : Recherchez des codes de réduction et des coupons que vous pouvez utiliser pour réduire le prix de la location. Louer à un emplacement hors aéroport peut parfois entraîner des prix plus bas.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
