import { IconMail, IconMailOpened, IconPhone } from "@tabler/icons-react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import { IconLocation } from "@tabler/icons-react";

function Contact() {
  return (
    <>
      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">
          <div className="contact-div">
            <div className="contact-div__text">
              <h2>Besoin d'informations complémentaires ?</h2>
              <p>
              BIDAWI AUTO est là pour vous fournir la voiture parfaite pour votre voyage au Maroc.
              </p>
              <a href="tel:+212660016653">
                <IconPhone /> &nbsp; (212) 660-016653
              </a>
              <a href="mailto:bidawi.auto@gmail.com">
                <IconMail /> &nbsp; bidawi.auto@gmail.com
              </a>
              <a href="#">
                <IconLocation />
                &nbsp; Fès, Maroc
              </a>
            </div>
            <div className="contact-div__form">
              <form>
                <label>
                  Nom complet <b>*</b>
                </label>
                <input type="text" placeholder='E.g: "Said Banoun"'></input>

                <label>
                  Tel <b>*</b>
                </label>
                <input type="tel" placeholder="0652555555"></input>

                <label>
                  Message <b>*</b>
                </label>
                <textarea placeholder="Écrivez ici.."></textarea>

                <button type="submit" style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                  <IconMailOpened />
                  &nbsp; Envoyer le message
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Réservez une voiture en nous contactant</h2>
              <span>
                <IconPhone width={40} height={40} />
                <h3>(212) 660-016653</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Contact;
