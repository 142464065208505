import { IconMail, IconPhoneCall } from "@tabler/icons-react";
import { IoCash } from "react-icons/io5";
import { FaMoneyCheck } from "react-icons/fa";
import { BiSolidBank } from "react-icons/bi";


function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span>Location de</span> voiture
              </li>
              <li>
                Nous proposons une large gamme de véhicules pour tous vos
                besoins de conduite. Nous avons la voiture parfaite pour
                répondre à vos besoins.
              </li>
              <li>
                <a href="tel:0660016653">
                  <IconPhoneCall /> &nbsp; (212) -660-016653
                </a>
              </li>

              <li>
                <a
                  href="mailto: 
                  bidawiauto@gmail.com"
                >
                  <IconMail />
                  &nbsp; bidawiauto@gmail.com
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://freelanceprojets.vercel.app/"
                >
                  FreeLance Projets
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li> Réseaux sociaux </li>
              <li>
                <a href="https://web.facebook.com/Bidawi.Auto" target="_blank">Facebook</a>
              </li>
              <li>
                <a href="https://www.instagram.com/bidawiauto" target="_blank">Instagram</a>
              </li>
             

              <li>
                <a href="/Conditions">Terms et conditions</a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Horaires de travail</li>
              <li>24H/24</li>
              <li>7jours/7</li>
            </ul>

            <ul className="footer-content__2 payment">
              <li>Modes de paiement</li>
              <li>
              <IoCash />
              <span>En espèces</span>
              </li>
              <li>
              <FaMoneyCheck />
              <span>Par chèque</span>
              </li>
              <li>
              <BiSolidBank />
              <span>Par virement bancaire</span>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
