import {
  FaPhone,
  FaEnvelope,
  FaMapMarker,
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";

function BannerTop() {
  return (
    <div className="banner-top">
      <div className="icon-bar">
        <a href="tel:+212660016653" className="contact-link-top" target="_blank">
          <FaPhone className="contact-icon" />
          <span className="contact-text">+212 660-016653</span>
        </a>
        <a href="mailto:bidawiauto@gmail.com" className="contact-link-top hide-on-mobile" target="_blank">
          <FaEnvelope className="contact-icon" />
          <span className="contact-text">bidawiauto@gmail.com</span>
        </a>
        <a href="https://maps.app.goo.gl/5ZqTpcUPG5Wu7rZt8" className="contact-link-top hide-on-mobile" target="_blank">
          <FaMapMarker className="contact-icon" />
          <span className="contact-text">Fès, Maroc</span>
        </a>
        <a href="https://web.facebook.com/Bidawi.Auto" className="social-link" target="_blank">
          <FaFacebook className="social-icon" />
        </a>
        <a href="https://www.instagram.com/bidawiauto" className="social-link" target="_blank">
          <FaInstagram className="social-icon" />
        </a>
        <a href="https://api.whatsapp.com/send/?phone=212660016653" className="social-link" target="_blank">
          <FaWhatsapp className="social-icon" />
        </a>
      </div>
    </div>
  );
}

export default BannerTop;
