import React from "react";
import corsa from "../images/cars-big/corsa2024.webp";
import logan from "../images/cars-big/logan2024.webp";
import clio from "../images/cars-big/clio55.webp";
import peugeot208 from "../images/cars-big/peugeot208.webp";
import C3 from "../images/cars-big/c3-2024.webp";
import duster from "../images/cars-big/duster.webp";
import { TbManualGearboxFilled } from "react-icons/tb";
import { FaGasPump, FaUser, FaRegSnowflake } from "react-icons/fa";
import { GiCarDoor } from "react-icons/gi";

const FleetListItem = () => {
  return (
    <section id="vehicules" className="pick-section">
      <div className="container">
        <div className="pick-container">
          <div className="pick-container__title">
            <h3>Modèles de véhicules</h3>
            <h2>Notre flotte de location</h2>
            <p>
              Choisissez parmi une variété de nos incroyables véhicules à louer
              pour votre prochaine aventure ou voyage d'affaires
            </p>
          </div>
          <div className="pick-container__car-content">
            {/* pick car */}
            <div className="fleet-list row row-cols-1 row-cols-sm-2 row-cols-md-3">
              <div className="my-3 col">
                <div aria-label="fleet-list-item" className="h-100 card">
                  <div className="p-3 card-body">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ paddingBottom: "2rem", paddingTop: "2rem" }}
                    >
                      <img
                        alt="Fiat 500"
                        loading="lazy"
                        width="305"
                        height="178"
                        decoding="async"
                        data-nimg="1"
                        className="img-fluid"
                        srcSet={corsa}
                        src={corsa}
                        style={{ color: "transparent" }}
                      />
                    </div>
                    <h2 className="h5">
                      Opel Corsa
                      <small className="ml-1 text-muted"> ou similaire</small>
                      <br />
                      <small className="mb-3">
                        Modèle: <span className="text-muted">2024</span>
                      </small>
                    </h2>
                    <div className="d-flex flex-wrap justify-content-center">
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Boîte manuelle"
                      >
                        <TbManualGearboxFilled className="icon-react" />
                        <span className="ml-1">Boîte manuelle</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Carburant"
                      >
                        <FaGasPump />
                        <span className="ml-1">Diesel</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <FaUser />
                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <GiCarDoor />
                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de bagages"
                      >
                        <FaRegSnowflake />
                      </div>
                    </div>
                    <a
                      className="btn mt-2 btn-colors btn-large btn-block rounded"
                      href="#booking-section"
                    >
                      Réserver
                    </a>
                  </div>
                </div>
              </div>
              <div className="my-3 col">
                <div aria-label="fleet-list-item" className="h-100 card">
                  <div className="p-3 card-body">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ paddingBottom: "2rem", paddingTop: "2rem" }}
                    >
                      <img
                        alt="Fiat 500"
                        loading="lazy"
                        width="305"
                        height="178"
                        decoding="async"
                        data-nimg="1"
                        className="img-fluid"
                        srcSet={logan}
                        src={logan}
                        style={{ color: "transparent" }}
                      />
                    </div>
                    <h2 className="h5">
                      Dacia Logan
                      <small className="ml-1 text-muted"> ou similaire</small>
                      <br />
                      <small className="mb-3">
                        Modèle: <span className="text-muted">2024</span>
                      </small>
                    </h2>
                    <div className="d-flex flex-wrap justify-content-center">
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Boîte manuelle"
                      >
                        <TbManualGearboxFilled className="icon-react" />
                        <span className="ml-1">Boîte manuelle</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Carburant"
                      >
                        <FaGasPump />
                        <span className="ml-1">Diesel</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <FaUser />

                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <GiCarDoor />
                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de bagages"
                      >
                        <FaRegSnowflake />
                      </div>
                    </div>
                    <a
                      className="btn mt-2 btn-colors btn-large btn-block rounded"
                      href="#booking-section"
                    >
                      Réserver
                    </a>
                  </div>
                </div>
              </div>
              <div className="my-3 col">
                <div aria-label="fleet-list-item" className="h-100 card">
                  <div className="p-3 card-body">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ paddingBottom: "2rem", paddingTop: "2rem" }}
                    >
                      <img
                        alt="Fiat 500"
                        loading="lazy"
                        width="305"
                        height="178"
                        decoding="async"
                        data-nimg="1"
                        className="img-fluid"
                        srcSet={clio}
                        src={clio}
                        style={{ color: "transparent" }}
                      />
                    </div>
                    <h2 className="h5">
                      Renault Clio 5
                      <small className="ml-1 text-muted"> ou similaire</small>
                      <br />
                      <small className="mb-3">
                        Modèle: <span className="text-muted">2024</span>
                      </small>
                    </h2>
                    <div className="d-flex flex-wrap justify-content-center">
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Boîte manuelle"
                      >
                        <TbManualGearboxFilled className="icon-react" />
                        <span className="ml-1">Boîte manuelle</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Carburant"
                      >
                        <FaGasPump />
                        <span className="ml-1">Diesel</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <FaUser />

                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <GiCarDoor />
                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de bagages"
                      >
                        <FaRegSnowflake />
                      </div>
                    </div>
                    <a
                      className="btn mt-2 btn-colors btn-large btn-block rounded"
                      href="#booking-section"
                    >
                      Réserver
                    </a>
                  </div>
                </div>
              </div>
              <div className="my-3 col">
                <div aria-label="fleet-list-item" className="h-100 card">
                  <div className="p-3 card-body">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ paddingBottom: "2rem", paddingTop: "2rem" }}
                    >
                      <img
                        alt="Fiat 500"
                        loading="lazy"
                        width="305"
                        height="178"
                        decoding="async"
                        data-nimg="1"
                        className="img-fluid"
                        srcSet={peugeot208}
                        src={peugeot208}
                        style={{ color: "transparent" }}
                      />
                    </div>
                    <h2 className="h5">
                      Peugeot 208
                      <small className="ml-1 text-muted"> ou similaire</small>
                      <br />
                      <small className="mb-3">
                        Modèle: <span className="text-muted">2024</span>
                      </small>
                    </h2>
                    <div className="d-flex flex-wrap justify-content-center">
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Boîte manuelle"
                      >
                        <TbManualGearboxFilled className="icon-react" />
                        <span className="ml-1">Boîte manuelle</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Carburant"
                      >
                        <FaGasPump />
                        <span className="ml-1">Diesel</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <FaUser />

                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <GiCarDoor />
                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de bagages"
                      >
                        <FaRegSnowflake />
                      </div>
                    </div>
                    <a
                      className="btn mt-2 btn-colors btn-large btn-block rounded"
                      href="#booking-section"
                    >
                      Réserver
                    </a>
                  </div>
                </div>
              </div>
              <div className="my-3 col">
                <div aria-label="fleet-list-item" className="h-100 card">
                  <div className="p-3 card-body">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ paddingBottom: "2rem", paddingTop: "2rem" }}
                    >
                      <img
                        alt="Fiat 500"
                        loading="lazy"
                        width="305"
                        height="178"
                        decoding="async"
                        data-nimg="1"
                        className="img-fluid"
                        srcSet={C3}
                        src={C3}
                        style={{ color: "transparent" }}
                      />
                    </div>
                    <h2 className="h5">
                      Citreon C3
                      <small className="ml-1 text-muted"> ou similaire</small>
                      <br />
                      <small className="mb-3">
                        Modèle: <span className="text-muted">2024</span>
                      </small>
                    </h2>
                    <div className="d-flex flex-wrap justify-content-center">
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Boîte manuelle"
                      >
                        <TbManualGearboxFilled className="icon-react" />
                        <span className="ml-1">Boîte manuelle</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Carburant"
                      >
                        <FaGasPump />
                        <span className="ml-1">Diesel</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <FaUser />

                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <GiCarDoor />
                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de bagages"
                      >
                        <FaRegSnowflake />
                      </div>
                    </div>
                    <a
                      className="btn mt-2 btn-colors btn-large btn-block rounded"
                      href="#booking-section"
                    >
                      Réserver
                    </a>
                  </div>
                </div>
              </div>
              <div className="my-3 col">
                <div aria-label="fleet-list-item" className="h-100 card">
                  <div className="p-3 card-body">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ paddingBottom: "2rem", paddingTop: "2rem" }}
                    >
                      <img
                        alt="Fiat 500"
                        loading="lazy"
                        width="305"
                        height="178"
                        decoding="async"
                        data-nimg="1"
                        className="img-fluid"
                        srcSet={duster}
                        src={duster}
                        style={{ color: "transparent" }}
                      />
                    </div>
                    <h2 className="h5">
                      Dacia Duster
                      <small className="ml-1 text-muted"> ou similaire</small>
                      <br />
                      <small className="mb-3">
                        Modèle: <span className="text-muted">2024</span>
                      </small>
                    </h2>
                    <div className="d-flex flex-wrap justify-content-center">
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Boîte manuelle"
                      >
                        <TbManualGearboxFilled className="icon-react" />
                        <span className="ml-1">Boîte manuelle</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Carburant"
                      >
                        <FaGasPump />
                        <span className="ml-1">Diesel</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <FaUser />

                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de sièges"
                      >
                        <GiCarDoor />
                        <span className="ml-1">5</span>
                      </div>
                      <div
                        className="Fleet_features-icon__6rAYf"
                        title="Nombre de bagages"
                      >
                        <FaRegSnowflake />
                      </div>
                    </div>
                    <a
                      className="btn mt-2 btn-colors btn-large btn-block rounded"
                      href="#booking-section"
                    >
                      Réserver
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FleetListItem;
