import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import PlanTrip from "../components/PlanTrip";
import PickCar from "../components/PickCar";
import Banner from "../components/Banner";
import ChooseUs from "../components/ChooseUs";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import PickCar2 from "../components/FleetListItem";
import FleetListItem from "../components/FleetListItem";

function Home() {
  return (
    <>
      <Hero />
      <ChooseUs />
      <Banner />
      <FleetListItem />
      <BookCar />
      <PlanTrip />
      <Faq />   
      <Footer />
    </>
  );
}

export default Home;
